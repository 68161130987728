<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div v-click-outside="onClickOutside" class="container">
        <img
          alt="close"
          class="close"
          src="@/assets/icons/close-modal.svg"
          @click="onClickOutside"
        />
        <div class="header">
          <div class="badge">
            <img alt="x100" src="@/assets/icons/roulette-x100.svg" />
          </div>
          <span>Поздравляем вы выиграли!</span>
        </div>
        <div class="flex">
          <div class="block">
            <div class="stats">
              <span class="score text-primary"
                >{{ data.coeff | fixedTo }}x</span
              >
              <span class="description">Общий X</span>
            </div>
          </div>
          <div class="block">
            <div class="stats">
              <span class="score text-warning"
                >{{ data.dopCoeff | fixedTo }}x</span
              >
              <span class="description">Дополнительный X</span>
            </div>
          </div>
        </div>
        <div class="stats">
          <span class="score text-success">{{
            convertAmount(data.winSum, "rub")
          }}</span>
          <span class="description">Сумма выигрыша</span>
        </div>
        <div class="title">
          Игроки выиграли: {{ convertAmount(data.allwin, "rub") }}
        </div>
        <div class="player" v-for="(user, index) in data.users" :key="index">
          <span>{{ user.name }}</span>
          <span class="text-primary">X{{ data.coeff }}</span>
          <span class="text-success">{{
            convertAmount(user.winSum, "rub")
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vClickOutside from "v-click-outside";
import { mapGetters, mapState } from "vuex";

export default {
  computed: {
    ...mapGetters("user", ["convertAmount"]),
    ...mapState("x100", ["data"]),
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  methods: {
    onClickOutside() {
      this.$store.commit("x100/showModal", false);
    },
  },
  filters: {
    fixedTo(value) {
      return Number(value).toFixed(2);
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-mask {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;
    .container {
      width: 540px;
      margin: 0px auto;
      background-color: white;
      background-image: url("~@/assets/icons/particles-winmodal.png");
      background-size: cover;
      border-radius: 34px;
      //   display: flex;
      padding: 30px;
      position: relative;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
      img.close {
        position: absolute;
        right: 30px;
        top: 45px;
        transition: 200ms ease;
        &:hover {
          cursor: pointer;
          opacity: 0.7;
        }
      }
      .stats {
        background: #ffffff;
        box-shadow: 0px 15px 45px rgba(0, 0, 0, 0.05);
        border-radius: 14px;
        margin-bottom: 20px;
        padding: 30px 0;
        span {
          text-align: center;
          display: block;
          &.score {
            font-weight: 500;
            font-size: 30px;
            margin-bottom: 5px;
          }
          &.description {
            font-size: 14px;
            color: #9599aa;
          }
        }
      }
      .flex {
        display: flex;
        justify-content: space-between;
        .block {
          width: calc(50% - 10px);
        }
      }

      .title {
        text-align: center;
        font-weight: 500;
        font-size: 18px;
        margin-bottom: 15px;
      }

      .text-primary {
        color: #0085ff;
      }
      .text-warning {
        color: #dca933;
      }
      .text-success {
        color: #60be73;
      }

      .header {
        display: flex;
        align-items: center;
        font-weight: 600;
        font-size: 20px;
        margin-bottom: 15px;
        span {
          text-align: center;
          display: block;
          width: calc(100% - 45px - 15px - 35px);
        }
        .badge {
          img {
            width: 18px;
            height: 18px;
            object-fit: contain;
          }
          background-color: #0085ff;
          // background-image: url("~@/assets/icons/btn-pattern-transparent 1.png");
          border-radius: 8px;
          display: flex;
          width: 45px;
          height: 45px;
          margin-right: 15px;
          align-items: center;
          justify-content: center;
        }
      }

      .player {
        background: #ffffff;
        box-shadow: 0px 15px 45px rgba(0, 0, 0, 0.05);
        border-radius: 8px;
        margin-bottom: 15px;
        &:last-child {
          margin-bottom: 0;
        }
        display: flex;
        align-items: center;
        height: 60px;
        padding: 0 25px;
        span {
          font-weight: 500;
          width: calc(100% / 3);
          &:last-child {
            text-align: right;
          }
        }
      }
    }
  }
}
</style>
