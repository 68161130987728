<template>
  <div v-if="buttons" class="buttons">
    <button
      v-for="(x, color) in buttons"
      :key="color"
      :class="color"
      @click="$emit('bet', color)"
    >
      x{{ x * manyX }}
      <span v-if="banks[color] > 0" class="bank"
        >{{ banks[color] | fixedNumber }} ₽</span
      >
    </button>
  </div>
</template>

<script>
import { xs } from "@/config/x100";
export default {
  props: {
    manyX: Number,
    banks: Object,
  },
  data() {
    return {
      buttons: xs,
    };
  },
  filters: {
    fixedNumber(value) {
      return Number(value).toFixed(0);
    },
  },
};
</script>

<style lang="scss" scoped>
.buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 1080px;
  flex-wrap: wrap;
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 260px;
    height: 75px;
    border-radius: 10px;
    margin-bottom: 15px;
    color: white;
    font-size: 25px;
    font-weight: 500;
    text-transform: uppercase;
    position: relative;
    text-shadow: 2px 2px rgba(black, 0.1);
    span {
      background-color: rgba(255, 255, 255, 0.25);
      padding: 3px 8px;
      border-radius: 5px;
      margin-left: 10px;
    }
    &.lightblue {
      background: linear-gradient(180deg, #43d4e3 0%, #208ddc 100%);
    }
    &.yellow {
      background: linear-gradient(180deg, #f3cc31 0%, #de9b15 100%);
    }
    &.pink {
      background: linear-gradient(180deg, #fb70fd 0%, #e93fec 100%);
    }
    &.green {
      background: linear-gradient(180deg, #63e47e 0%, #37aa4e 100%);
    }
    &.purple {
      background: linear-gradient(180deg, #bb79fe 4.17%, #9543e7 100%);
    }
    &.orange {
      background: linear-gradient(180deg, #ffad4e 0%, #ff6939 100%);
    }
    &.blue {
      background: linear-gradient(180deg, #55abfe 0%, #6763f4 100%);
    }
    &.red {
      background: linear-gradient(180deg, #ff7979 0%, #ff3f3f 100%);
    }

    &::after {
      content: "";
      position: absolute;
      left: 10px;
      right: 10px;
      bottom: 10px;
      top: 10px;
      border-radius: 10px;
      border: 1px solid rgba(#ffffff, 0.25);
    }
    transition: 200ms ease;
    &:hover {
      opacity: 0.8;
      cursor: pointer;
    }
  }

  @media (max-width: 768px) {
    width: 100%;
    button {
      width: calc(100% / 2 - 5px);
    }
  }
}
</style>
