<template>
  <div class="history">
    <form
      v-for="game in history"
      :key="game.id"
      action="https://api.random.org/verify"
      method="post"
      target="_blank"
    >
      <input type="hidden" name="format" value="json" />
      <input type="hidden" name="random" :value="game.random" />
      <input type="hidden" name="signature" :value="game.signature" />
      <button type="submit" :class="game.winner_color"></button>
    </form>
    <!-- <span v-for="game in history" :key="game.id" :class="game.winner_color" /> -->
  </div>
</template>

<script>
export default {
  props: {
    history: Array,
  },
};
</script>

<style lang="scss" scoped>
.history {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  form {
    margin-right: 7px;
  }
  button {
    display: block;
    width: 5px;
    height: 20px;
    cursor: pointer;
    background-color: black;
    border-radius: 100px;
    transition: 200ms ease;
    &:hover {
      opacity: 0.7;
      width: 10px;
    }
    &.lightblue {
      background-color: #00ffff;
      height: 20px;
    }

    &.yellow {
      background-color: #ffe600;
      height: 25px;
    }

    &.red {
      background-color: #ff0000;
      height: 100px;
    }

    &.pink {
      background-color: #ff5bbe;
      height: 33px;
    }

    &.blue {
      background-color: #306aff;
      height: 80px;
    }

    &.purple {
      background-color: #7d17ff;
      height: 60px;
    }

    &.orange {
      background-color: #ffa500;
      height: 70px;
    }

    &.green {
      background-color: #39ae51;
      height: 50px;
    }

    &.black {
      background-color: #3a3e46;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}
</style>
