<template>
  <div class="features">
    <span v-if="access" class="text" @click="$emit('repeat', 2)">
      <span>x2</span>
      Удвоить
    </span>

    <div
      v-for="(amount, coin) in coins"
      :key="coin"
      class="coin"
      :class="{ active: coin === active, [coin]: true }"
      @click="change(coin)"
    >
      {{ amount }}
    </div>

    <span v-if="access" class="text" @click="$emit('repeat', 1)">
      <span>
        <img alt="Повторить" src="@/assets/images/x100/sync.svg" />
      </span>
      Повторить
    </span>
  </div>
</template>

<script>
export default {
  props: {
    access: Number,
  },
  data() {
    return {
      active: "yellow",
      coins: {
        yellow: 1,
        purple: 10,
        lightblue: 50,
        green: 250,
        orange: 1000,
        red: 2500,
      },
    };
  },
  methods: {
    change(color) {
      this.$data.active = color;
      this.$emit("setAmount", this.$data.coins[color]);
    },
  },
};
</script>

<style lang="scss" scoped>
.features {
  // margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  span.text {
    display: flex;
    align-items: center;
    margin: 0 20px;
    color: #3a3e46;
    font-weight: 600;
    transition: 200ms ease;
    &:hover {
      opacity: 0.8;
      cursor: pointer;
    }
    span {
      color: #fc6565;
      margin-right: 5px;
    }
  }
  .coin {
    margin: 0 5px;
    width: 77px;
    height: 77px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 22px;
    font-weight: 500;
    text-shadow: 2px 2px rgba(black, 0.1);
    border-radius: 50%;
    &.green {
      background-image: url("~@/assets/images/x100/coin-green.png");
      &.active {
        border: 5px solid #39ae51;
      }
    }
    &.lightblue {
      background-image: url("~@/assets/images/x100/coin-lightblue.png");
      &.active {
        border: 5px solid #6ba1f3;
      }
    }
    &.orange {
      font-size: 17px;
      background-image: url("~@/assets/images/x100/coin-orange.png");
      &.active {
        border: 5px solid #f2be46;
      }
    }
    &.purple {
      background-image: url("~@/assets/images/x100/coin-purple.png");
      &.active {
        border: 5px solid #7043f0;
      }
    }
    &.red {
      font-size: 17px;
      background-image: url("~@/assets/images/x100/coin-red.png");
      &.active {
        border: 5px solid #ff4141;
      }
    }
    &.yellow {
      background-image: url("~@/assets/images/x100/coin-yellow.png");
      &.active {
        border: 5px solid #f2be46;
      }
    }
    background-size: cover;
    background-position: center;
    transition: 200ms ease;
    &:hover {
      opacity: 0.7;
      cursor: pointer;
    }
  }
}
</style>
