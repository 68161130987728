<template>
  <div>
    <div class="x100">
      <Header />
      <div class="body">
        <Timer :centrifuge="centrifuge" />
        <div class="wheel-container">
          <img
            alt="Курсор"
            class="cursor"
            src="@/assets/images/x100/cursor.png"
          />
          <svg
            :style="styleObject"
            viewBox="0 0 600 600"
            ref="chart"
            preserveAspectRatio="xMidYMid meet"
          ></svg>
        </div>
        <History :history="response.history" />
      </div>
    </div>

    <div
      v-if="isLoggedIn"
      class="bets"
      :class="{
        disabled: centrifuge.type === 'slider',
      }"
    >
      <Buttons @bet="bet" :manyX="manyX" :banks="getUserAmounts" />
      <Features
        @setAmount="setAmount"
        :access="bets.data.length"
        @repeat="repeatBets"
      />
    </div>

    <WinModal v-if="winModal" />
  </div>
</template>

<script>
import Header from "@/components/Games/X100/Header";
import Buttons from "@/components/Games/X100/Buttons";
import Timer from "@/components/Games/X100/Timer";
import Features from "@/components/Games/Features";
import History from "@/components/Games/X100/History";
import { mapState, mapGetters } from "vuex";
import * as d3 from "d3";
import { colors } from "@/config/x100";
import WinModal from "@/components/Games/WinModal";

export default {
  data() {
    return {
      wheelTransform: 0,
      centrifuge: {},
      broadcast: null,
      positions: [],
      manyX: 1,
      amount: 1,
      currentRotate: 0,
      styleObject: {
        transform: "rotate(0deg)",
        transition: "0s cubic-bezier(0, 0.49, 0, 1) -7ms",
      },
      chart: {},
      bets: {
        id: 0,
        data: [],
      },
      response: {
        bets: [],
        wheel: {},
        history: [],
        game: { id: 0, status: 0 },
      },
      lock: {
        slider: false,
      },
    };
  },

  computed: {
    ...mapState("user", ["user"]),
    ...mapState("x100", ["winModal"]),
    ...mapGetters("user", {
      isLoggedIn: "isLoggedIn",
      userBalance: "getBalance",
      convertAmount: "convertAmount",
    }),
    getUserAmounts() {
      const object = {
        lightblue: 0,
        yellow: 0,
        pink: 0,
        green: 0,
        purple: 0,
        orange: 0,
        blue: 0,
        red: 0,
      };

      const bets = this.response.bets;
      for (let i in bets) {
        const bet = bets[i];
        object[bet.color] += this.convertAmount(bet.sum, bet.currency);
      }

      return object;
    },
  },

  async mounted() {
    try {
      let { data } = await this.$http.get("games/wheels/x100/init");
      this.response = data.response;
      this.wheelCreate();
      this.events();
    } catch (error) {
      this.$toast.error(error?.message || "Ошибка отправки запрсоа");
    }
  },

  destroyed() {
    if (this.broadcast !== null) this.broadcast.unsubscribe();
  },

  methods: {
    events() {
      this.broadcast = this.$centrifuge.subscribe(
        "games:x100",
        async ({ data }) => {
          // console.log(data);
          this.centrifuge = data;

          // Добавление в историю текущее значение
          if (data.type === "slider" && data.time === 0) {
            const gameId = this.response.game.id;
            this.response.history.unshift({
              id: gameId,
              winner_color: data.winner_color,
              random: data.random,
              signature: data.signature,
            });
          }

          // При начале новой игры заменяем данные, чтобы была возможность повтора ставок
          if (data.type === "new") {
            this.styleObject.transition = "0s cubic-bezier(0, 0.49, 0, 1) -7ms";
            this.lock.slider = false;
            this.manyX = 1;
            this.response.game.id = data.id;
            this.response.bets = [];
            this.response.history = this.response.history.splice(0, 50);
          }

          // Прокрутка
          if (data.type === "slider" && this.lock.slider === false) {
            this.lock.slider = true;
            const sliders = data.slider;
            const timeout = data.time / sliders.length - 2;
            this.styleObject.transition =
              timeout + "s cubic-bezier(0, 0.49, 0, 1) -7ms";
            for (let i in sliders) {
              const slider = sliders[i];
              this.currentRotate =
                Math.round(this.currentRotate / 360) * 360 +
                this.wheelRotate(slider - 1);
              // console.log("currentRotate", this.currentRotate);
              this.styleObject.transform = `rotate(${this.currentRotate}deg)`;
              await new Promise((resolve) =>
                setTimeout(resolve, (timeout + 2) * 1000)
              );
              const el = this.response.wheel[slider];
              if (typeof el === "number") this.manyX *= el;
            }
          }
        }
      );
    },

    // Ставка
    async bet(color, amountData = this.amount) {
      try {
        const currency = this.userBalance.active;
        const amount = this.convertAmount(amountData, currency, false);

        let { data } = await this.$http.post("games/wheels/x100/place", {
          amount,
          currency,
          color,
        });

        if (data.error) return this.$toast.error(data.error);
        this.$store.commit("user/balance", data.response.balances);

        this.$toast.success("Вы успешно поставили ставку");

        if (this.bets.id !== this.response.game.id) this.bets.data = [];
        this.bets.data.push({ color, amount: amountData });

        const responseBets = this.response.bets;
        const betIndex = responseBets.findIndex((bet) => bet.color === color);

        if (betIndex === -1)
          responseBets.push({ color, currency, sum: amount });
        else {
          const bet = responseBets[betIndex];
          bet.sum = Number(bet.sum) + Number(amount);
        }
      } catch (error) {
        this.$toast.error(error.message);
      }
    },

    //
    async repeatBets(repeat = 1) {
      this.bets.id = this.response.game.id;
      // Удвоить - повторить
      for (let i = 1; i <= repeat; i++) {
        for (let bid in this.bets.data) {
          const bet = this.bets.data[bid];
          await this.bet(bet.color, bet.amount);
        }
      }
      this.bets.data = [];
      // this.bets.data.forEach((bet) => this.bet(bet.color, bet.amount));
    },

    setAmount(amount) {
      this.amount = amount;
    },

    wheelRotate(index) {
      let angles = this.positions[index];
      return 1.3 - angles.endAngle * 159 * 0.36 + 360 * 6;
    },

    // Рендеринг колеса
    wheelCreate() {
      const wheel = Object.values(this.response.wheel);
      let width = 600;
      let height = 600;
      let outerRadius = height / 2;
      let innerRadius = outerRadius - 8;
      let cornerRadius = 3;
      let padAngle = 0.005;

      let pie = d3
          .pie()
          .sort(null)
          .value(() => 1)
          .padAngle(padAngle),
        arcs = pie(wheel);

      this.positions = arcs;
      // window._positions = arcs;

      let arc = d3
          .arc()
          .innerRadius(innerRadius)
          .outerRadius(outerRadius)
          .cornerRadius(cornerRadius),
        svg = d3
          .select(this.$refs.chart)
          .append("g")
          .attr("transform", "translate(" + width / 2 + "," + height / 2 + ")");

      svg
        .append("g")
        .selectAll("path")
        .data(arcs)
        .enter()
        .append("path")
        .style("fill", ({ data }) => {
          if (typeof data === "number") return colors.black;
          return colors[data];
        })
        .attr("d", arc);

      svg
        .selectAll("text")
        .data(arcs)
        .enter()
        .append("text")
        .style("text-anchor", "middle")
        .style("alignment-baseline", "middle")
        .style("font-size", "5px")
        .style("font-weight", "bold")
        .style("fill", "white")
        .attr("transform", (d) => {
          const rotateText = (360 / arcs.length) * d.index + 1;
          return (
            "translate(" + arc.centroid(d) + ") rotate(" + rotateText + ") "
          );
        })
        .text((d) => (typeof d.data === "number" ? d.data + "x" : ""));
    },
  },

  watch: {
    "user.cent_token"() {
      this.events();
    },
  },

  components: {
    Header,
    Buttons,
    Features,
    Timer,
    History,
    WinModal,
  },
};
</script>

<style lang="scss" scoped>
.x100 {
  border-radius: 8px;
  padding: 25px 0 0 0;
  margin-bottom: 25px;
  background-color: white;
  .body {
    position: relative;
    height: 350px;
    overflow: hidden;
    position: relative;
    .wheel-container {
      position: relative;
      margin-top: 20px;

      img.cursor {
        width: 45px;
        height: 45px;
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        top: -20px;
        z-index: 1;
      }
      display: flex;
      justify-content: center;
      $width: 1700px;
      svg {
        margin-top: 20px;
        width: $width;
        height: $width;
        min-width: $width;
        min-height: $width;
        max-width: $width;
        max-height: $width;
      }
    }
  }
}

.bets {
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 25px;
  background-color: white;
  margin-top: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}
</style>
