<template>
  <div v-if="centrifuge.type !== 'slider'" class="timer">
    <div class="desc">До начала игры:</div>
    <div class="time">{{ centrifuge.time | convertTime }}</div>
  </div>
</template>

<script>
export default {
  props: {
    centrifuge: Object,
  },
  filters: {
    convertTime(e = 0) {
      let t = Math.floor(e / 60),
        n = e - 60 * t;
      return t < 10 && (t = "0" + t), n < 10 && (n = "0" + n), t + ":" + n;
    },
  },
};
</script>

<style lang="scss" scoped>
.timer {
  width: 173px;
  z-index: 1;
  text-align: center;
  position: absolute;
  right: 20px;
  top: 20px;
  .desc {
    font-size: 15px;
    color: #a3afc9;
    margin-bottom: 5px;
  }
  .time {
    font-size: 25px;
    font-weight: bold;
  }
}
</style>
