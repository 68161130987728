const colors = {
  lightblue: "#42DFEE",
  yellow: "#ffeaa7",
  pink: "#FB71FE",
  red: "#FF7A7A",
  black: "#6C6E8B",
  green: "#64E57F",
  orange: "#FBC26F",
  blue: "#55ADFF",
  purple: "#9543e7",
};

const xs = {
  lightblue: 1,
  yellow: 2,
  pink: 5,
  green: 10,
  purple: 15,
  orange: 20,
  blue: 25,
  red: 100,
};

export { colors, xs };
